// Import dependencies
import React from "react";

// Import components
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";

// Import styles
import "./404.css";
import { PageContainer } from "../styled-components/UILibrary";
import { Link } from "gatsby";
import SearchBar from "../components/search-bar/searchBar";

const ApplicationSent = () => (
    <Layout>
      <SEO title="Application sent" />
      <div className="only-mobile">
      </div>
      <SearchBar/>
  
      {/* Page content */}
      <PageContainer>
  
          <div className="save_profil_success application_sent_container">
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 40C0 17.92 17.92 0 40 0C62.08 0 80 17.92 80 40C80 62.08 62.08 80 40 80C17.92 80 0 62.08 0 40ZM14.7994 42.8002L29.1594 57.1602C30.7194 58.7202 33.2794 58.7202 34.7994 57.1602L65.1594 26.8002C66.7194 25.2402 66.7194 22.7202 65.1594 21.1602C63.5994 19.6002 61.0794 19.6002 59.5194 21.1602L31.9994 48.6802L20.4394 37.1602C18.8794 35.6002 16.3594 35.6002 14.7994 37.1602C14.0504 37.9075 13.6295 38.9221 13.6295 39.9802C13.6295 41.0382 14.0504 42.0528 14.7994 42.8002Z" fill="#1AA260"/>
            </svg>
            <h1>Application sent</h1>
            <span>We’ll be in touch shortly. In the meantime, please check your inbox for more information</span>
          </div>
  
      </PageContainer>
    </Layout>
  );
  
  export default ApplicationSent;

